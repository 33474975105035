import { Col, Row } from 'antd';
import React from 'react';
import vehicle from 'assets/images/vehicle.svg'
import appStoreLogo from 'assets/images/appstore.svg'
import googlePlay from 'assets/images/googleplay.svg'
import mobileOne from 'assets/images/mobile-one.svg'
import mobileTwo from 'assets/images/mobile-two.svg'
import mobileThree from 'assets/images/mobile-three.svg'
import CONSTANTS from 'constants/constants';
import useWindowDimensions from 'utils/WindowDimensions'

const Home = () => {
  const { HOME } = CONSTANTS
  const { width } = useWindowDimensions()

  return (
    <div id='home' className='home-container'>
      <div className='home-header'>{HOME.HEADER_TEXT}</div>
      <Row >
        <Col span={ width > 767 ? 12 : 24 }>
          <p className='home-body'>{HOME.HOME_BODY}</p>
          <div className='text-center'>
            <img className='vehicle-img-mobile vehicle-img' alt='vehicle-logo' src={vehicle} />
          </div>
          <div className='store-links'>
            <img className='store-img-mobile' alt='google-play-logo' src={googlePlay} />
            <img className='store-img-mobile' alt='appstore-logo' src={appStoreLogo} />
          </div>
        </Col>
        <Col span={12} className='dl-flex home-images'>
          <div className='mobile-one p-relative'>
            <img alt='mobile-logo-one' src={mobileOne} />
          </div>
          <div className='mobile-two p-relative'>
            <img alt='mobile-logo-two' src={mobileTwo} />
          </div>
          <div className='mobile-one mobile-three p-relative'>
            <img alt='mobile-logo-three' src={mobileThree} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
