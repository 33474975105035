const CONSTANTS = {
      PRIVACY_POLICY: 'Privacy Policy',
      HOME_TEXT: 'Home',
      HOME: {
        HEADER_TEXT: "Simplify Logistics with HappyFleet's Container Shelf Intelligence",
        HOME_BODY: 'Embark on a journey of global efficiency with HappyFleet! Trusted by users in the top 10 countries worldwide, our cutting-edge delivery and container shelf arrangement solution redefine logistics on an international scale'
      },
      CONTACT_US_TEXT: 'Contact Us',
      CONTACT_US: 'Contact Our Friendly Team',
      FOOTER_TEXT: '© 2024 Happy Fleet. All rights reserved.'
  }
  
  export default CONSTANTS
  