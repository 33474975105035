import React from 'react';
import HappyFleetLogo from 'assets/images/happy-fleet-logo.svg'
import { Menu, Layout } from 'antd';

const Header = (props) => {
  const { items, current, handleClick } = props
  const { Header } = Layout;

  return (
    <Header className='header-container'>
        <div className='display-flex'>
          <img alt='happy-fleet-logo' src={HappyFleetLogo} width={40} height={40} />
          <Menu className='header-menu' onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={items} />
        </div>
      </Header>
  );
};

export default Header;
