import React from 'react';
import CONSTANTS from 'constants/constants';
import URLS from 'constants/urls';

const ContactUs = () => {

  return (
    <div id='contact' className='contact-container' >
      <div className='privacy-heading'>
        <h1 className='privacy-header'>{CONSTANTS.CONTACT_US}</h1>
      </div>
      <div className='contact-forms'>
        <iframe 
          className='contact-frame'
          title='Contact form'
          src={URLS.GOOGLE_FORM} 
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
          >
            Loading…
          </iframe>
        </div>
    </div>
  );
};

export default ContactUs;
