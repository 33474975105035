
import 'components/styles.css';
import { Route, Routes } from 'react-router-dom';
import MainPage from 'components/MainPage';
import Header from 'components/Header';
import CONSTANTS from 'constants/constants';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const items = [
  {
    label: <Link to='#home'>{CONSTANTS.HOME_TEXT}</Link>,
    key: 'home',
  },
  {
    label: <Link to='#privacy'>{CONSTANTS.PRIVACY_POLICY}</Link>,
    key: 'privacy',
  },
  {
    label: <Link to='#contact'>{CONSTANTS.CONTACT_US_TEXT}</Link>,
    key: 'contact',
  },
];

const App = () => {
  const value = window.location.hash.substring(window.location.hash.lastIndexOf('#')+1);
  const [current, setCurrent] = useState(value? value : 'home');
  const handleClick = (e) => {
    setCurrent(e.key);  
  };

  return (<>
    <Header 
        items={items} 
        current={current}
        handleClick={handleClick}
      />
    <Routes>
      <Route exact path="/" element={<MainPage current={current}/>} />
    </Routes>
    </>
  );
};
export default App;