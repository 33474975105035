import React, { useEffect } from 'react';
import { Layout } from 'antd';
import 'components/styles.css';
import Home from 'components/Home';
import Privacy from 'components/Privacy';
import ContactUs from 'components/ContactUs';
import CONSTANTS from 'constants/constants';

const MainPage = (props) => {
  const { current } = props
  const { Footer } = Layout;

  useEffect(() => {
    setTimeout (()=> {
      const sectionElement = document.getElementById(current);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },500)
  }, [current]);
  
  return (
    <Layout >
      <Home />
      <Privacy />
      <ContactUs />
      <Footer className='footer'>
        {CONSTANTS.FOOTER_TEXT}
      </Footer>
    </Layout>
  );
};
export default MainPage;