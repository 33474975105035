const PrivacyData = {
    data:[
        {
            id:1,
            key:'Data Collection and Usage - User Data Access and Enhanced User Functionality',
            value:"Our application utilizes Data abstraction from the QR code. By granting us camera permission, we scan PDF417 and process required data including receiver's location to provide efficient route for teh delivery services. The Apps distributed on Google Play/ Apple AppStore are also subject to the Developer Distribution Agreement. \n We use your location access to schedule and manage delivery routes."
        },
        {
            id:2,
            key:'Data Storage and Security - Data Storage and Limited Use of Data',
            value:'We do not store any of your data in persistent storage.\n We will only use your data to provide the auothorized access as described in this policy. Your data will not be used for any other purpose without your explicit consent.'
        },
        {
            id:3,
            key:'Data Sharing - Third-Party Service Providers and Aggregated and Anonymized Data',
            value:'We do not share your data with any third-party service providers. \n We may aggregate and anonymize data for analytical purposes, improving our services, and reporting. Such data will not include any personally identifiable information.'
        },
        {
            id:4,
            key:'Privacy Policy Link - Accessible Privacy Policy',
            value:'Our comprehensive Privacy Policy is available on our website and can be accessed through the link provided on our home page.'
        },
        {
            id:5,
            key:"Limited Use Requirements - Compliance with Guidelines",
            value:"We comply with AppStore/PlayStore limited Use Requirements for accessing and using User data. Our application's usage adheres to these guidelines to protect your privacy and data security."
        },
        {
            id:6,
            key:'Verified Domain and Accessibility -  Verified Domain and Accessibility',
            value:'Our application is hosted on a verified domain under our ownership, ensuring a secure and trusted environment for our users. \n Our Privacy and Data Usage Policy is accurate, inclusive, and easily accessible to all users. It is published on an externally and publicly accessible domain, providing complete transparency regarding the content and context of our application.'
        },
        {
            id:7,
            key:'Contact Information',
            value:"If you have any questions, concerns, or require assistance regarding this Privacy and Data Usage Policy or our application's data usage, please feel free to contact our support team at: http://www.carismeta.com/contact.\n By using our application, you acknowledge that you have read, understood, and agreed to this Privacy and Data Usage Policy. Your continued use of our application constitutes your acceptance of these terms."
        }
    ]
}
  
export default PrivacyData
  