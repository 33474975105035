import { Col, Row } from 'antd';
import React, { useState } from 'react';
import PrivacyData from 'assets/data/privacyData';
import privacyImg from 'assets/images/privacy.svg';
import CONSTANTS from 'constants/constants';
import useWindowDimensions from 'utils/WindowDimensions'

const Privacy = () => {
  const [selectedKey, setSelectedKey] = useState(`${PrivacyData.data[0].id}. ${PrivacyData.data[0].key}` )
  const [selectedValue, setSelectedValue] = useState(`${PrivacyData.data[0].value}`)
  const { width } = useWindowDimensions()
  
  return (
    <div id='privacy' className='privacy-container'>
      <div className='privacy-heading'>
        <h1 className='privacy-header'>{CONSTANTS.PRIVACY_POLICY}</h1>
      </div>
      <Row className='dl-flex'>
        <Col span={ width > 767 ? 10 : 24} className='privacy-side-menu'>
          <div className='privacy-inner-heading'>{CONSTANTS.PRIVACY_POLICY}</div>
          {PrivacyData.data.map((item, key) =>  
            <div className='privacy-side-key' key={key} onClick={()=> {
              setSelectedKey(`${item.id}. ${item.key}`)
              setSelectedValue(`${item.value}`)
            }}>
              {item.id}. {item.key}
            </div>
          )}
        </Col>
        <Col span={ width > 767 ? 12 : 24} className='privacy-value'>
          <div className='privacy-body-key'>{selectedKey}</div>
          <div className='privacy-body-value'>{selectedValue}</div>
          <div className='privacy-img'>
            <img alt='privacy-img' className='privacy-img-mobile' src={privacyImg} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Privacy;
